<script setup>
import { HeartIcon, ChatBubbleLeftIcon, BookmarkIcon } from '@heroicons/vue/24/outline'
import moment from "moment";

defineProps({
    video: {
        type: Object,
        required: true
    }
})

const formatViews = (views) => {
    if (views >= 1000000) {
        return `${(views / 1000000).toFixed(1)}M views`
    }
    if (views >= 1000) {
        return `${(views / 1000).toFixed(1)}K views`
    }
    return `${views} views`
}
</script>

<template>
    <a class="group cursor-pointer" :href="'/videos/' + video.identifier">
        <div class="relative">
            <img
                :src="video.thumbnails[0] || '/images/placeholder-image.jpg'"
                :alt="video.title"
                class="w-full aspect-video object-cover rounded-xl"
            >
            <span v-if="video.document?.duration" class="absolute bottom-2 right-2 px-2 py-1 bg-black/80 text-white text-xs rounded">
                {{ video.document.duration }}
            </span>
            <div class="absolute inset-0 bg-black/20 group-hover:bg-black/40 transition-colors rounded-xl"></div>
        </div>
        <div class="mt-3 flex gap-3">
            <img
                :src="video.user?.thumbnail?.url || '/images/default.png'"
                :alt="video.user?.name || 'Unknown User'"
                class="w-9 h-9 rounded-full"
            >
            <div>
                <h3 class="font-medium text-white group-hover:text-blue-400 line-clamp-2">
                    {{ video.title }}
                </h3>
                <p class="text-gray-400 text-sm mt-1">{{ video.user?.name || 'Unknown' }}</p>
                <div class="text-gray-400 text-sm">
                    {{ formatViews(video.views) }} • {{ moment(video.created_at).fromNow() }}
                </div>
            </div>
        </div>
    </a>
</template>
